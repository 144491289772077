$(function() {
//BOF

// Show/hide images
$(".show-image").on("click", function() {
  var imgUrl = $(this).attr('href');
  var imgTrigger = $(this);

  $(imgTrigger).siblings(".full-image").hide().html("<a href='" + imgUrl + "' title='View full size'><img src='" + imgUrl + "' alt=''></a>").show();
  $(imgTrigger).hide();
  $(imgTrigger).siblings(".hide-image").show();
  $(this).siblings(".thumbnail").hide();
  return false;
});

$(".hide-image").on("click", function() {
  var hideTrigger = $(this);

  $(hideTrigger).hide();
  $(hideTrigger).siblings(".show-image").show().siblings(".full-image").hide();
  $(this).siblings(".thumbnail").show();
  return false;
});

// Tooltip
$("body.desktop .show-image").on("mouseover", function() {
  var thumbDiv = $(this).siblings(".thumbnail");
  var thumbURL = $(thumbDiv).attr("data-thumbnail");
  $(thumbDiv).css({
    backgroundImage: 'url(' + thumbURL + ')'
  });
});

// Escape key to close images (vanilla)
document.onkeydown = function (evt) {
  if (!evt) evt = event;
  if (evt.keyCode == 27) {
    $(".full-image").hide();
    $(".hide-image").hide();
    $(".show-image").show();
    $("#toggle-nav").prop('checked', false);
  }
}

// sinister
if (localStorage.getItem("sinister") == "true") {
  $("body").addClass("sinister");
  $('#sinister').prop('checked', true);
}
$('#sinister').change(function() {
  $("body").toggleClass("sinister");
  var c = this.checked ? true : false;
  localStorage.setItem("sinister", c);
});

// persist checkboxes

var checkboxValues = JSON.parse(localStorage.getItem('checkboxValues')) || {}, $checkboxes = $(".toggle-list");

  $checkboxes.on("change", function(){

    $checkboxes.each(function(){
      checkboxValues[this.id] = this.checked;
    });

  localStorage.setItem("checkboxValues", JSON.stringify(checkboxValues));

});

// On page load
$.each(checkboxValues, function(key, value) {
  $("#" + key).prop('checked', value);
});

$('.toggler-header, .toggler-footer').addClass('fadeInTogglers');

//EOF
});
